
import robertJohnson from '../../src/images/robert-johnson.jpg';
import jimiHendrix from '../../src/images/jimi-hendrix.jpg';
import jimMorrison from '../../src/images/jim-morrison.jpg';
import janisJoplin from '../../src/images/janis-joplin.jpg';
import brianJones from '../../src/images/brian-jones.jpg';
import kurtCobain from '../../src/images/kurt-cobain.jpg';
import amyWinehouse from '../../src/images/amy-winehouse.jpg';

export const initialCards = [
  {
    id: 1,
    name: 'Роберт Джонсон',
    image: robertJohnson,
    url: 'robert-johnson',
    dates: '08.05.1911 — 16.08.1938',
    text: [
      'Ро́берт Леро́й Джо́нсон (англ. Robert Leroy Johnson; 8 мая 1911 года, Хейзелхёрст, Миссисипи, США — 16 августа 1938 года, Гринвуд, Миссисипи, США) — американский певец, гитарист и автор песен, один из наиболее известных блюзменов XX века.',
      'После знакомства в 1930 году с известными блюзменами Саном Хаусом и Вилли Брауном, Роберт Джонсон пытался овладеть игрой на гитаре в стиле блюз, чтобы выступать вместе с товарищами. Однако искусство это давалось ему крайне тяжело. На какое-то время Роберт расстался с друзьями и исчез, а когда появился в 1931 году, уровень его мастерства возрос многократно. По этому поводу Джонсон рассказывал байку о том, что есть некий магический перекрёсток, на котором он заключил сделку с дьяволом в обмен на умение играть блюз.',
      'В самых известных своих песнях — «Me and the Devil Blues» , «Hellhound on My Trail», «Cross Road Blues», «Up Jumped The Devil» — он непосредственно упоминает об этом. Эти произведения повлияли как на современников Джонсона (Хаулин Вулф, Мадди Уотерс, Элмор Джеймс), так и на более поздних рок-исполнителей (Эрик Клэптон, Джими Хендрикс, Кит Ричардс, Джимми Пейдж).',
      'Роберт Лерой Джонсон умер 16 августа 1938 года. В сертификате найденном в 1968 году причиной смерти указан сифилис. Хотя и существует романтичная легенда об отравлении Джонсона ревнивым супругом, звучащая лучше, чем смерть на обочине дороги в результате запущенного венерического заболевания. Его место погребения искали долгое время, сейчас считается что он захоронен под деревом рядом с церковью у города Гринвуд в Мисисипи. 16 августа 1938 года девушка по имени Рози Эскридж видела, как ее муж и другие люди, закапывали неизвестного мужчину у корней пеканового дерева недалеко от церкви рядом с городом Гринвуд, а партнер самого Джонсона - Ханибой Эдуардс рассказывал, что музыканта захоронили в 1600 метрах от клуба "3 Forks Juke Joint" в котором они играли в тот вечер, место захоронения было рядом с церковью.'
    ],
  },
  {
    id: 2,
    name: 'Брайан Джонс',
    image: brianJones,
    url: 'brian-jones',
    dates: '28.02.1942 — 03.06.1970',
    text: [
      'Лью́ис Бра́йан Хо́пкин Джонс (англ. Lewis Brian Hopkin Jones; 28 февраля 1942, Челтнем, Глостершир, Англия — 3 июля 1969, Хартфилд, Восточный Суссекс, Англия) — основатель, гитарист, мультиинструменталист и бэк-вокалист британской рок-группы The Rolling Stones.',
      'Джонс был человеком, благодаря которому группа The Rolling Stones появилась на свет. Это он дал объявление о том, что собирает новую команду, на которое откликнулся сначала клавишник Иен Стюарт, а затем и Мик Джаггер с Китом Ричардсом. Именно Джонс побуждал друзей экспериментировать со звучанием, он был любителем нетривиальных аранжировок и экзотических инструментов. Без Джонса The Rolling Stones превратились в предсказуемую группу, из альбома в альбом повторяющую по сути одни и те же приемы.',
      'Хотя никто из The Rolling Stones в ранние годы не был поборником здорового образа жизни, наркотические пристрастия Джонса начали напрягать его коллег. Джонса, в свою очередь, напрягали сами The Rolling Stones — он начал подумывать о том, чтобы расстаться с командой и заняться сольным проектом. По слухам, Брайан хотел собрать супергруппу, позвав туда, в частности, Джими Хендрикса. В 1967 году девушка Джонса Анита Палленберг ушла от него к другому участнику Stones Киту Ричардсу, что только осложнило отношения в команде.',
      '8 июня 1969 года музыканты Stones предложили товарищу покинуть группу «по собственному желанию», что он и сделал на следующий день. Ночью 3 июля тело Джонса вытащили со дна бассейна в его поместье в Хартфилде. Полиция признала смерть результатом несчастного случая',
    ],
  },
  {
    id: 3,
    name: 'Джими Хендрикс',
    image: jimiHendrix,
    url: 'jimi-hendrix',
    dates: '27.11.1942 — 18.09.1970',
    text: [
      'Джи́ми Хе́ндрикс (англ. Jimi Hendrix; полное имя Джеймс Ма́ршалл Хе́ндрикс, англ. James Marshall Hendrix, имя при рождении Джонни Аллен Хендрикс, англ. Johnny Allen Hendrix; 27 ноября 1942, Сиэтл, Вашингтон, США — 18 сентября 1970, Лондон, Англия) — американский гитарист-виртуоз, певец и композитор. В 2009 году журнал «Time» назвал Хендрикса величайшим гитаристом всех времён. Широко признан как один из наиболее смелых и изобретательных виртуозов в истории рок-музыки.',
      'Хендрикса ещё при жизни называли феноменом и гением. Он открыл бесконечный источник возможностей нового звучания. Говоря о новшествах Хендрикса, критики подчёркивают, что он расширил диапазон и словарь электрической гитары и изменил лицо рок-музыки. Музыкой Хендрикса восхищались и восхищаются: Ричи Блэкмор, Ингви Мальмстин, Стив Вай, Джо Сатриани, Стиви Рэй Вон, Дэвид Гилмор, Майлз Дэвис, Фредди Меркьюри,Ульрих Рот, Маттиас Ябс, Марк Нопфлер, Эрик Клэптон, Джон Мэйер, Сид Барретт, Джон Фрушанте, Пит Таунсенд, Энтони Кидис, Ленни Кравиц, Пол Маккартни, Брайан Ино, Брайан Мэй, Зак Вайлд, Курт Кобейн, Фли.',
      'Утром 18 сентября 1970 года Хендрикс был найден мертвым в номере отеля Samarkand в Лондоне. Ему было всего 27 лет. Причиной смерти, указанной в официальном заключении, стало удушье от собственной рвоты в результате интоксикации барбитуратами. В 1993 году расследование смерти Хендрикса было продолжено Скотланд Ярдом, но поскольку новых доказательств найдено не было, дело закрыли.',
      'Джими Хендрикс похоронен в Гринвудском Мемориальном Парке в Рентоне, штат Вашингтон (США).',
    ],
  },
  {
    id: 4,
    name: 'Дженис Джоплин',
    image: janisJoplin,
    url: 'janis-joplin',
    dates: '19.01.1943 — 04.10.1970',
    text: [
      'Дже́нис Лин Джо́плин (англ. Janis Lyn Joplin; 19 января 1943, Порт-Артур, Техас, США — 4 октября 1970, Лос-Анджелес, Калифорния, США) — американская рок-певица, выступавшая сначала в составе Big Brother and the Holding Company, затем в Kozmic Blues Band и Full Tilt Boogie Band. Джоплин, выпустившая лишь четыре студийных альбома (один из которых — посмертный релиз), считается лучшей белой исполнительницей блюза и одной из величайших вокалисток в истории рок-музыки.',
      ' В 17 лет она впервые начала выступать — на сценах небольших клубов, ее голос услышали профессионалы, популярность росла, и в 1965-м она становится солисткой группы Big Brother and the Holding Company. С ней она фактически ворвалась на рок-сцену, играя смесь поп-психоделии и блюза, выпустила три студийных альбома, а потом рассталась, поскольку Джоплин стала звездой, а музыканты — лишь аккомпанирующим составом.',
      'Потом был альбом с группой Kozmic Blues Band, записи и гастроли с Full Tilt Boogie Band — но эта пластинка вышла только после смерти певицы.',
      '4 октября 1970 года тело Дженис Джоплин было найдено в Landmark Motor Hotel в Голливуде. Ее нашли, лежащей между кроватями, со сломанным носом и четырьмя с половиной долларами, зажатыми в кулаке. Полицейское расследование установило, что смерть наступила от случайной передозировки героина. Поверить в то, что молодая женщина в расцвете таланта и славы погибла по нелепой случайности, было сложно. Смерть Дженис породила волну слухов: некоторые считали, что она покончила с собой, кто-то утверждал, что ее убил бывший любовник. Нашлись и сторонники теории заговора, которые обвинили в смерти Дженис ни много ни мало правительство Соединенных Штатов',
      '',
    ],
  },
  {
    id: 5,
    name: 'Джим Моррисон',
    image: jimMorrison,
    url: 'jim-morrison',
    dates: '08.12.1943 — 03.07.1971',
    text: [
      'Джим Мо́ррисон (англ. Jim Morrison; полное имя Джеймс Ду́глас Мо́ррисон, англ. James Douglas Morrison; 8 декабря 1943, Мелборн, Флорида — 3 июля 1971, Париж) — американский поэт, певец, автор песен, лидер и вокалист группы The Doors.',
      'Считается одним из самых харизматичных фронтменов в истории рок-музыки. Моррисон известен как своим характерным голосом, так и своеобразностью собственной сценической фигуры, саморазрушительным стилем жизни и своим поэтическим творчеством. Журнал Rolling Stone включил его в список 100 величайших певцов всех времён.',
      'Поступив в UCLA, на факультет кинематографии, он ведет богемный образ жизни, много читает, принимает психотропные вещества, увлекается мистицизмом и идеями бит-поколения. Дипломная работа Джима вызывает неоднозначную реакцию преподавателей, и он со скандалом покидает университет.',
      'Вскоре со своим другом, также студентом UCLA Рэем Манзареком и присоединившимися к ним гитаристом Робби Кригером и барабанщиком Джоном Денсмором, они создают квартет the Doors, взяв название из строчки Олдоса Хаксли: «If the doors of perception were cleansed,/Every thing would appear to man as it is, infinite» (русск. Если бы двери восприятия были чисты/Всё предстало бы таким, как есть — бесконечным).',
      'Употребление галлюциногенов, в частности LSD, оказало непосредственное влияние на творчество Джима и Doors: мистицизм и шаманизм стали частью сценического акта. «I am a Lizard king. I can do anything.» — сказал про себя Джим в одной из песен («Я король-ящерица, я всё могу»',
      '3 июля 1971 года Джим Моррисон был найден мертвым в ванне своей квартиры. Причиной смерти стала сердечная недостаточность. Поэт и музыкант был похоронен на кладбище Пер-Лашез в Париже. Место захоронения музыканта стало объектом паломничества поклонников Моррисона и группы The Doors.',
    ],
  },

  {
    id: 6,
    name: 'Курт Кобейн',
    image: kurtCobain,
    url: 'kurt-cobain',
    dates: '20.02.1967 — 05.04.1994',
    text: [
      'Курт До́нальд Кобе́йн (англ. Kurt Donald Cobain; 20 февраля 1967, Абердин, Вашингтон — 5 апреля 1994, Сиэтл, Вашингтон) — американский рок-музыкант, вокалист, гитарист и автор песен. Наиболее известен как основатель и лидер рок-группы «Нирвана».',
      'Кобейн считается одним из ключевых музыкантов гранжевой сцены и входит в число 100 величайших гитаристов всех времён по версии журнала Rolling Stone. В 2014 году был посмертно включён в Зал славы рок-н-ролла в составе «Нирваны».',
      'В середине 1980-х годов Кобейн начал увлекаться панк-роком, а в 1987 году вместе с Кристом Новоселичем образовал группу «Нирвана». После заключения договора с инди-лейблом Sub Pop «Нирвана» выпустила свой дебютный альбом Bleach. На волне интереса к сиэтлской рок-музыке группа подписала контракт со звукозаписывающей компанией DGC Records.',
      'Второй альбом «Нирваны» — Nevermind — вышел в 1991 году и принёс Курту Кобейну всемирную известность. Песня «Smells Like Teen Spirit» стала хитом, а сам альбом поднялся на вершину альбомного чарта Billboard 200. Стиль сиэтлских групп альтернативного рока получил название «гранж» и стал самым популярным направлением рок-музыки первой половины 1990-х. Кобейна называли «голосом поколения» из-за бунтарского образа, запоминающейся музыки и искренних текстов песен, выражавших эмоции подростков того времени.',
      'Обратной стороной славы Кобейна стали его депрессия и зависимость от героина. После женитьбы на рок-певице Кортни Лав и рождения ребёнка музыка отошла для него на второй план. В составе «Нирваны» музыкант успел выпустить сборник редких песен Incesticide (1992) и альбом In Utero (1993), но из-за проблем со здоровьем ему пришлось прервать гастроли. После нескольких тщетных попыток излечиться от наркотической зависимости 5 апреля 1994 года Курт Кобейн покончил жизнь самоубийством.',
    ],
  },
  {
    id: 7,
    name: 'Эми Уайнхаус',
    image: amyWinehouse,
    url: 'amy-winehouse',
    dates: '14.09.1983 — 23.07.2011',
    text: [
      'Э́ми Джейд Уайнха́ус (англ. Amy Jade Winehouse; 14 сентября 1983, Саутгейт, Лондон, Англия — 23 июля 2011, Камден, Лондон, Англия) — британская певица и автор песен, известная своим контральто-вокалом и эксцентричным исполнением смеси музыкальных жанров, включая R&B, соул и джаз, признанная критиками одной из ведущих британских исполнительниц 2000-х годов.',
      'Дважды лауреат премии Ivor Novello, её первый альбом Frank (2003) был номинирован на Mercury Prize. 14 февраля 2007 года певица получила Brit Award как «Лучшая британская исполнительница» («Best British Female Artist»). Второй альбом Back to Black (2006) принёс ей 6 номинаций «Грэмми» и победу в 5 из них (включая Record of the Year), после чего она была занесена в Книгу рекордов Гиннесса как первая и единственная британская певица, выигравшая пять наград Грэмми. В августе 2011 года альбом «Back to Black» признан самым успешным альбомом XXI века в Великобритании.',
      'Уайнхаус внесла значительный вклад в популяризацию соул-музыки, а также британской музыки. Её запоминающийся стиль в одежде сделал её музой для модельеров, таких как Карл Лагерфельд.',
      'Уайнхаус, страдавшая от алкогольной и наркотической зависимости, долгое время находилась в центре публичных скандалов и судебных разбирательств.',
      'Скончалась 23 июля 2011 года в своём доме в Камдене от сердечного приступа, вызванного алкогольной интоксикацией, в возрасте 27 лет.',
    ],
  },
];